import { useEffect } from 'react'
import BigNumber from 'bignumber.js'
import { fetchFarmsPublicDataAsync, fetchFarmsPublicDataAsync_ape } from './actions'
import { State, FarmsState, Farm } from './types'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import useRefresh from 'hooks/useRefresh'
// import { useWeb3React } from '@web3-react/core'
import { farmsConfig, farmsConfig_ape,farmsConfig_sushi } from 'config/constants'
import { fetchFarmUserDataAsync, nonArchivedFarms } from './farms'
import { fetchFarmUserDataAsync_ape, nonArchivedFarms_ape } from './farms_ape'
import { fetchFarmsPublicDataAsync_sushi, fetchFarmUserDataAsync_sushi, nonArchivedFarms_sushi } from './farms_sushi'
export const usePollFarmsData = (account?, includeArchive = false) => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()

  // const account = "0x856b63349fB6c818ea7cD7305483Ae0EF6956f6c";
  // const account = account1

  useEffect(() => {
    const farmsToFetch = includeArchive ? farmsConfig : nonArchivedFarms
    const farmsToFetch_ape = includeArchive ? farmsConfig_ape : nonArchivedFarms_ape
    const farmsToFetch_sushi = includeArchive ? farmsConfig_sushi : nonArchivedFarms_sushi
    const pids = farmsToFetch.map((farmToFetch) => farmToFetch.pid)
    const pids_ape = farmsToFetch_ape.map((farmToFetch) => farmToFetch.pid)
    const pids_sushi = farmsToFetch_sushi.map((farmToFetch) => farmToFetch.pid)
  
    dispatch(fetchFarmsPublicDataAsync(pids))
    dispatch(fetchFarmsPublicDataAsync_ape(pids_ape))
 
    if (account) {
 
      dispatch(fetchFarmsPublicDataAsync_sushi(pids_sushi))
      dispatch(fetchFarmUserDataAsync_sushi({ account, pids_sushi }))
      dispatch(fetchFarmUserDataAsync_ape({ account, pids_ape }))
     dispatch(fetchFarmUserDataAsync({ account, pids }))
    
    }
  }, [includeArchive, dispatch, slowRefresh, account])

}

export const useFarms = (): FarmsState => {
  const farms = useSelector((state: State) => state.farms)
  return farms
}
export const useFarms_ape = (): FarmsState => {
  const farms_ape = useSelector((state: State) => state.farms_ape)
  return farms_ape
}
export const useFarms_sushi = (): FarmsState => {
  const farms_sushi = useSelector((state: State) => state.farms_sushi)
  return farms_sushi
}

export const usePriceCakeBusd = (): BigNumber => {
  const cakeBnbFarm = useFarmFromPid(251)
// console.log("cakeBnbFarm",cakeBnbFarm)
  return new BigNumber(cakeBnbFarm.token.busdPrice)
}

export const usePriceBananaBusd = (): BigNumber => {
  const bananaBnbFarm = useFarmFromPidApe(1)
  // console.log("bananaBnbFarm",bananaBnbFarm)
  return new BigNumber(bananaBnbFarm.token.busdPrice)
}

export const usePriceSushiBusd = (): BigNumber => {
  const sushiEthFarm = useFarmFromPidSushi(12)
  // console.log("bananaBnbFarm",bananaBnbFarm)
  return new BigNumber(sushiEthFarm.token.busdPrice)
}

export const usePriceBnbBusd = (): BigNumber => {
  const bnbBusdFarm = useFarmFromPid(252)
//  console.log("cakeBnbFarm",bnbBusdFarm)
  return new BigNumber(bnbBusdFarm.quoteToken.busdPrice)
}

export const useFarmFromPid = (pid): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid))
  return farm
}
export const useFarmFromPidApe = (pid): Farm => {
  const farm = useSelector((state: State) => state.farms_ape.data.find((f) => f.pid === pid))
  return farm
}

export const useFarmFromPidSushi = (pid): Farm => {
  const farm = useSelector((state: State) => state.farms_sushi.data.find((f) => f.pid === pid))
  return farm
}