import React, { useEffect, useState } from 'react'
import useRefresh from 'hooks/useRefresh'
import axios from 'axios'
import { async } from 'q'
import BigNumber from 'bignumber.js'
export default function Header() {
    const [price, setPrice] = useState([{ symbol: '', lastPrice: '', priceChangePercent: '' }])
    const [check, setCheck] = useState(true)
    const [slowRefresh, setslowRefresh] = useState(true)
    const [selectPrice, setSelectprice] = useState(['VEMOUSDT','BTCUSDT', 'ETHUSDT', 'BNBUSDT','DOGEUSDT','XRPUSDT','USDCUSDT','ADAUSDT','SHIBUSDT'])
   useEffect(() => {
      setTimeout(() => {
        setslowRefresh(!slowRefresh)
      }, 30000);
   }, [slowRefresh])

    useEffect(() => {


        const listPrice = []
        const setStyle = () => {
            selectPrice.map((res, index) => {
                let elPrice = document.getElementById(index + '-price')
                let elArrow = document.getElementById(index + '-arrow')
                let elPricepercent = document.getElementById(index + '-pricePercent')
                let elPrice1 = document.getElementById(index + '-price1')
                let elArrow1 = document.getElementById(index + '-arrow1')
                let elPricepercent1 = document.getElementById(index + '-pricePercent1')
                let pricePercent = '0'
                elPrice.style.color = 'white'
                elPrice1.style.color = 'white'
                try {
                    pricePercent = price[index].priceChangePercent ? price[index].priceChangePercent : '0';

                } catch (error) {
                    console.log(error);
                }

                if (parseFloat(pricePercent) < 0) {
                    elArrow.classList.remove('fa-arrow-up')
                    elArrow.classList.add('fa-arrow-down')
                    elPricepercent.style.color = 'red'
                    elArrow1.classList.remove('fa-arrow-up')
                    elArrow1.classList.add('fa-arrow-down')
                    elPricepercent1.style.color = 'red'
                } else if (parseFloat(pricePercent) > 0) {
                    elArrow.classList.remove('fa-arrow-down')
                    elArrow.classList.add('fa-arrow-up')
                    elPricepercent.style.color = '#37ff00'
                    elArrow1.classList.remove('fa-arrow-down')
                    elArrow1.classList.add('fa-arrow-up')
                    elPricepercent1.style.color = '#37ff00'
                } else {
                    elPricepercent.style.color = 'white'
                    elPricepercent1.style.color = 'white'
                }
            })
        }
        const getPrice = async () => {
            await axios.get('https://api.dex-trade.com/v1/public/ticker?pair=VEMOUSDT').then(async (res) => {
                let low = res.data.data.low;
                let high = res.data.data.high;
                let last = res.data.data.last
                let percent=0;

                if(high-last < last-low){
                    percent =((last - low) * 100) / low;
                }
                else{
                    percent =((last - high) * 100) / high;  
                }
               listPrice.push({ symbol:res.data.data.pair, lastPrice:new BigNumber(last) , priceChangePercent:percent.toFixed(3) })
            }
            )
            await axios.get('https://api.binance.com/api/v3/ticker/24hr').then(async (res) => {
                await res.data.map((res, index) => {
                    if (selectPrice.includes(res.symbol)) {
                        let last = new BigNumber(res.lastPrice)
                       
                        listPrice.push({ symbol: res.symbol, lastPrice:last, priceChangePercent:res.priceChangePercent});
                    }
                })
               console.log(listPrice)
                setPrice(listPrice)
            }
            )    
            setStyle()
        }
        getPrice()
    }, [slowRefresh])
    return (
        <div className='p-2 mt-3  ' style={{ backgroundColor: '#216681' }}>
            <div id="outer" className='' style={{ margin: '0 auto' }}>
                <div className='col-12'>
                    <div id="loop" className=''>
                        <div id="content" className=' d-flex flex-row justify-content-end' style={{ color: 'white' }}>
                            {price.map((res, index) => {
                                let result = '0'
                                if (res.lastPrice != '0') {
                                    result = res.lastPrice;
                                }
                                return <h2 className='mx-5 d-flex flex-row align-items-center p-2 border-start'>
                                    <img style={{ maxWidth: '155px', margin: '0px 3px 0px 8px' }}
                                        src={`images/${res.symbol}.png`} alt="" />
                                    <div>

                                        <span id={index + '-price'} className="down-price">{" $" + result + " "}</span>
                                        <span id={index + '-pricePercent'} className="down-pricePercent"><i id={index + '-arrow'} className="fas mx-2" /> {res.priceChangePercent + ' %'}</span>
                                    </div>
                                </h2>
                            }
                            )} &nbsp;

                        </div>
                    </div>
                    <div id="loop2" className=''>
                        <div id="content" className='' style={{display:'flex', flexDirection:'row', paddingTop:'1px', color: 'white',height:'50px',alignItems:'center'}}>
                            {price.map((res, index) => {
                                let result = '0'
                                if (res.lastPrice != '0') {
                                    result = res.lastPrice;
                                }
                                // return <h5 className='mx-5 d-inline-flex flex-row align-items-center p-2 border-start'>
                                return <h2 className='mx-5 d-flex flex-row align-items-center p-2 border-start'>
                                <img style={{ maxWidth: '155px', margin: '0px 3px 0px 8px' }}
                                    src={`images/${res.symbol}.png`} alt="" />
                                <div>

                                    <span id={index + '-price1'} className="down-price">{" $" + result + " "}</span>
                                    <span id={index + '-pricePercent1'} className="down-pricePercent"><i id={index + '-arrow1'} className="fas mx-2" /> {res.priceChangePercent + ' %'}</span>
                                </div>
                            </h2>
                            }
                            )} &nbsp;

                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}

