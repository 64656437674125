import React, { useEffect, useState } from 'react';
import multicall from 'utils/multicall';
import erc20ABI from 'config/abi/erc20.json'
import BigNumber from 'bignumber.js';
import { token2 } from 'config/constants/token2';


function TokenWallet(props) {
    const [balances1, setBalance1] = useState([])
    const symbol = token2
    const account = props.data
    const select = props.addcount
    const fetchAllBalances = async () => {
      // console.log('tokenwallet',props.data[select]);
        const calls = symbol.map((farm) => {
            return {
                address: farm.address[56],
                name: 'balanceOf',
                params: [props.data[select]]
            }
        })
        try {
            const resBalance = await multicall(erc20ABI, calls)
         
            const parsedTokenBalances = resBalance.map((tokenBalance) => {
                return new BigNumber(tokenBalance).toJSON()
            })
                 //   console.log(parsedTokenBalances);
                setBalance1(parsedTokenBalances);
                 
         //   console.log(parsedTokenBalances);
        } catch (error) {
          //  console.log(error);
        }
    }

    useEffect(() => {
        if (account) {
            fetchAllBalances()
        }
      //  console.log(account);
    }, [account,props.addcount])

    return (
        <div className=' bg-box p-3' style={{borderRadius:'20px'}}>
            <div className='text-start mt-4 mb-4'><h2 className='fs-5'>WALLET ASSETS</h2></div>
            <div className='d-flex justify-content-end mb-3'><h2>TOTAL BALANCE</h2></div>
            {(props.show.show1 ||props.show.show2 ||props.show.show3) ? <div className='' style={{ height: '360px', overflow: 'scroll', overflowX: 'hidden', backgroundColor:'#322B67'}}>
                {balances1.map((token, index) => {
                    const balance = token / Math.pow(10, symbol[index].decimals)
                    const check = balance > 0.0000001;
                    return check ? <div className='row px-2' key={index}>
                        <div className='d-flex flex-row justify-content-between'>
                            <div className='d-flex flex-row mb-1' style={{ columnGap: '5px' }}><img className='' style={{ maxWidth: '30px' }} src={`images/tokens/${symbol[index].address[56]}.png`} alt="" /> <h3 className='mt-2 '>{symbol[index].symbol}</h3></div>
                            <div className='d-flex flex-row mb-2' style={{ columnGap: '10px' }}> <h3 className='mt-2 '>{balance.toFixed(6)}</h3> <h3 className='mt-2' style={{ fontSize: '12px' }}>{symbol[index].symbol}</h3></div>
                        </div>
                    </div> : <div key={index}></div>
                }
                )}
            </div> : <div></div>
            }
            {/* {props.show.show2 ? <div className='mt-3' style={{ height: '180px', overflow: 'scroll', overflowX: 'hidden',backgroundColor:'#322B67'}}>
                {balances2.map((token, index) => {
                    const balance = token / Math.pow(10, symbol[index].decimals)

                    const check = balance > 0.0000001;
                    return check ? <div className='row px-2' key={index}>
                        <div className='d-flex flex-row justify-content-between'>
                            <div className='d-flex flex-row mb-1' style={{ columnGap: '5px' }}><img className='' style={{ maxWidth: '30px' }} src={`images/tokens/${symbol[index].address[56]}.png`} alt="" /> <h3 className='mt-2 '>{symbol[index].symbol}</h3></div>
                            <div className='d-flex flex-row mb-2' style={{ columnGap: '10px' }}> <h3 className='mt-2 '>{balance.toFixed(6)}</h3> <h3 className='mt-2' style={{ fontSize: '12px' }}>{symbol[index].symbol}</h3></div>
                        </div>
                    </div> : <div key={index}></div>
                }
                )}
            </div> : <div></div>
            }
            {props.show.show3 ? <div className='mt-3' style={{ height: '180px', overflow: 'scroll', overflowX: 'hidden',backgroundColor:'#322B67'}}>
                {balances3.map((token, index) => {
                    const balance = token / Math.pow(10, symbol[index].decimals)
                    const check = balance > 0.0000001;
                    return check ? <div className='row px-2' key={index}>
                        <div className='d-flex flex-row justify-content-between'>
                            <div className='d-flex flex-row mb-1' style={{ columnGap: '5px' }}><img className='' style={{ maxWidth: '30px' }} src={`images/tokens/${symbol[index].address[56]}.png`} alt="" /> <h3 className='mt-2 '>{symbol[index].symbol}</h3></div>
                            <div className='d-flex flex-row mb-2' style={{ columnGap: '10px' }}> <h3 className='mt-2 '>{balance.toFixed(6)}</h3> <h3 className='mt-2' style={{ fontSize: '12px' }}>{symbol[index].symbol}</h3></div>
                        </div>
                    </div> : <div key={index}></div>
                }
                )}
            </div> : <div></div>
            } */}
        </div>
    );
}

export default TokenWallet;
