import React from 'react'

export default function Footer() {
    return (
        <footer className='w-100 ' style={{marginTop:'70px'}}>
       
            <div className='container' style={{marginBottom:'120px'}}>
                    <div className='d-flex flex-column  mt-3 text-center'>
                        <h2 className='fs-5'>Support farms</h2> 
                        <p className='mt-1' style={{fontSize:'0.7rem',color:'rgba(255, 253, 253, 0.651)'}}>list of farms we supports here</p>
                    </div>
                    <div className='row mt-3 mb-2 rounded-3 bg-box pb-5'>
                  
                        <div className='col-2'></div>
                        <div className='col-8 text-center mt-2'>
                            <a href="https://pancakeswap.finance/farms" target="_blank"> <img  className='size-farmsup' src="images/farms support/Pancake-01.svg" alt="" /></a>
                            <a href="https://apeswap.finance/farms" target="_blank"> <img  className='size-farmsup' src="images/farms support/ApeSwap-01.svg" alt="" /></a>
                            <a href="https://app.sushi.com/farm" target="_blank"> <img  className='size-farmsup' src="images/farms support/Sushi-01.svg" alt="" /></a>
                            </div>
                        <div className='col-2'></div>

                    </div>


                </div>

            <div className='col-12 justify-content-center d-flex flex-column ' style={{padding:'35px 0 ',backgroundColor:'#190F56',height:'90px',alignItems:'center'}}><h2 style={{textAlign:'center'}}>Copyright © 2021 VEMO assets board, All rights Reserved</h2> </div>
           
        </footer>
    )
}
