import React, { useEffect, useState } from 'react'
import TokenWallet from './tokenwallet'
import FarmCard from './farmCard'
import Stat from './stat';
import multicall from 'utils/multicall';
import erc20ABI from 'config/abi/erc20.json'
import { token } from 'config/constants/token-VEMO';
import Axios from "axios";
import web3NoAccount from 'utils/web3';
import axios from 'axios';

const web3 = web3NoAccount;
export default function Home() {
    const [count, setcount] = useState(1)
    const [select, setSelect] = useState(0)
    const [active, setActive] = useState([true, false, false])
    const [account, setaccount] = useState('')
    const [account1, setaccount1] = useState([])
    const [acc, setacc] = useState([])
    const [acc1, setacc1] = useState('')
    const [acc2, setacc2] = useState('')
    const [acc3, setacc3] = useState('')
    const [tmp1, settmp1] = useState('')
    const [tmp2, settmp2] = useState('')
    const [tmp3, settmp3] = useState('')
    const [show1, setshow1] = useState(false)
    const [show2, setshow2] = useState(false)
    const [show3, setshow3] = useState(false)

    const fetchAllBalances = async () => {

        
        if (count > 3) {
            const el = document.getElementById('add-alert') as HTMLElement
            el.style.width = '240px'
            el.innerText = 'Add Wallet 3 Times limit. Please delete your wallet first.'
            el.style.opacity = '1'
            setTimeout(() => {
                el.style.opacity = '0'
            }, 2500);
            return
        }
        if (account1.includes(account)) {
           
            const el = document.getElementById('add-alert') as HTMLElement
            el.innerText = 'You already add this wallet address'
            el.style.opacity = '1'
            setTimeout(() => {
                el.style.opacity = '0'
            }, 1500);

            return
        }
        try {
            const vemo = await multicall(erc20ABI, [{ address: token[0].address[56], name: 'balanceOf', params: [account] }]);
            
            if (vemo/Math.pow(10,9) < 100) {
                alert("VEMO Token not enough, Please buy at least 100 Token.")
                window.open('https://vemo.world/','_self');
            }
            const accBalance = await web3.eth.getBalance(account)
            await Axios.post('https://api.dev.vemo.world/totalwallet_value.php', { address: account,wallet_value : accBalance }).then((res)=>{
              
                    console.log(res)
                })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    })

            let tmpAccount = account1;
            let tmpacc = acc;

            let tmp = account.split('');
            tmp.forEach((item, i, Array) => { if (i > 5 && i < tmp.length - 3) { Array[i] = "" } else if (i == 4) { Array[4] = "..." } });
            tmpacc.push(tmp.join(''))
            setacc(tmpacc)

            if (count <= 3) {
                tmpAccount.push(account)
            }
     
            if (count <= 1) {
               setacc1(tmp.join('')); setaccount1(tmpAccount); setshow1(true); settmp1(account);
            }
            else if (count <= 2) {
         
                setacc1(acc[0]); setacc2(acc[1]); setaccount1(tmpAccount); setshow1(true); setshow2(true); settmp1(account1[0]); settmp2(account1[1]);
            }
            else if (count <= 3) {
       

                setacc1(acc[0]); setacc2(acc[1]); setacc3(acc[2]);
                setshow1(true); setshow2(true); setshow3(true);
                setaccount1(tmpAccount);
                settmp1(account1[0]); settmp2(account1[1]); settmp3(account1[2]);
            }
            setcount(count + 1);

             await Axios.post('https://api.dev.vemo.world/board_api.php', { address: account }).then((res)=>{
            //await Axios.post('http://localhost/api/board_api.php', { address: account }).then((res) => {
                //console.log(res)
            })
                .catch(function (error) {
                    // handle error
                    //console.log(error);
                })

            //console.log("account1", account1);

        } catch (error) {
           // console.log(error);
        }

       // console.log("clear");
        const el_input = document.getElementById('inputAddress') as HTMLInputElement
        el_input.value = ''
        setaccount('')
       // console.log("ab.value", ab.value);
    }
    function selectAccount(account, tmpacc) {
        //console.log("selectAccount", account1);
      //  console.log("tmpacc", tmpacc);
        const index = account1.indexOf(tmpacc)
        setSelect(index);
        if (account == 1) { setActive([true, false, false]); }
        else if (account == 2) { setActive([false, true, false]); }
        else { setActive([false, false, true]); }
    }
    function removeAccount(num, tmpacc) {
        setcount(count - 1);
        const index = account1.indexOf(tmpacc)
        const tmpactive = active
        tmpactive[index] = false
        setActive(tmpactive)
        account1.splice(index, 1)
        acc.splice(index, 1)

        if (num == 0) {

            setshow1(false)
        } else if (num == 1) {

            setshow2(false)
        }
        else if (num == 2) {

            setshow3(false)
        }
       // console.log('account', account1);
        alert("remove success")
    }
    function successActive(id) {
        const el = document.getElementById(id) as HTMLElement;
        el.style.opacity = '1';
        setTimeout(() => {
            el.style.opacity = '0';
        }, 500);
    }
    return (
        <div className='container row mx-auto justify-content-center mt-5' >
            <div className='d-flex mb-4  align-items-center'><div className='px-3 sub-header' style={{ borderRight: 'solid white 1px' }}> <h1>VEMO</h1> </div><div className='px-3' style={{ color: 'rgba(255, 253, 253, 0.651)' }}>History tracking on Binance Smart Chain</div></div>
            <div className='col-lg-6 col-md-12  mb-2' >
                <div className='bg-box' style={{ minHeight: '303px', position: 'relative', borderRadius: '10px' }}>
                    <form className="row  mx-auto p-2 " style={{ maxWidth: '640px', position: 'relative' }}>
                        <img src="images/vemo-image/Iconplanet.gif" style={{ maxWidth: '120px', position: 'absolute', top: '0', right: '0',padding:'0' }} alt="" />
                        <div className="row">
                            <div className='mt-2'><h2 className='fs-5'>Add wallet</h2></div>
                            <div className='mt-2'><h3>ONLY SUPPORT BINANCE SMART CHAIN / NETWORK TOKENS!</h3></div>
                            <div className='mt-4'><h2>Wallet address</h2></div>
                        </div>
                        <div className='d-flex flex-row justify-content-between'>
                            <div className="col-auto mt-2" style={{ width: '80%' }}>
                                <input type="text" className="form-control" id="inputAddress" placeholder="Enter Wallet address" value={account} onChange={(e) => setaccount(e.target.value)} />
                            </div>
                            <div className="col-auto mt-2 px-0" style={{ position: 'relative' }}>
                                <button className="btn btn-1" onClick={(e) => { e.preventDefault(); fetchAllBalances(); }}>Add</button>
                                <div id='add-alert'></div>
                            </div>
                        </div>
                    </form>
                    <div><img src="images/vemo-image/icon-rocket-05.gif" style={{ width: '100%', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }} />
                    </div>
                </div>
                {
                    show1 ? <div id='account-bar' className="account-bar row d-flex flex-column mx-auto p-2 rounded-3 mt-2 bg-box" >
                        <div className='d-flex flex-row justify-content-start' style={{ position: 'relative' }}><h2 className='fs-5'>Your Wallet</h2> <div id='copy-alert1'> Copy Success</div></div>
                        <div className='d-flex flex-row justify-content-between mt-3'>
                            <div className='d-flex flex-row' style={{ alignItems: 'center', cursor: 'pointer' }} onClick={() => selectAccount(1, tmp1)}><img style={{ maxWidth: '30px', fontSize: '12px' }} src="images/tokens/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c.png" alt="" /> <h2 className='p-1 acc' >{acc1}</h2></div>
                            {active[0] ?
                                <div className=' rounded-3' style={{ backgroundColor: '#FB53C1' }}>
                                    <h6 className='p-2' style={{ cursor: 'default', marginTop: '4px', color: 'white' }}>
                                        Active
                                    </h6>
                                </div>
                                :
                                <div></div>
                            }
                            {active[0] ? <div className='d-flex flex-row mt-1' style={{ columnGap: '0px' }}><i className="far fa-clone cursor-point btn copy" onClick={() => { navigator.clipboard.writeText(account1[0]); successActive('copy-alert1') }} />
                                <button className='btn-secondary rounded px-3 ' style={{ cursor: 'no-drop' }}><i className="far fa-trash-alt" /></button>
                            </div> :
                                <div className='d-flex flex-row mt-1' style={{ columnGap: '0px' }}><i className="far fa-clone cursor-point btn copy" onClick={() => { navigator.clipboard.writeText(account1[0]); successActive('copy-alert1') }} />
                                    <button className='btn-danger rounded px-3 cursor-point' onClick={() => { removeAccount(0, tmp1) }}><i className="far fa-trash-alt cursor-point" /></button>

                                </div>
                            }
                        </div>
                    </div> : <div></div>
                }
                {
                    show2 ? <div id='account-bar' className="account-bar row d-flex flex-column mx-auto p-2 rounded-3 mt-2 bg-box" >
                        <div className='d-flex flex-row justify-content-start' style={{ position: 'relative' }}><h2 className='fs-5'>Your Wallet</h2>  <div id='copy-alert2'> Copy Success</div></div>
                        <div className='d-flex flex-row justify-content-between mt-3'>
                            <div className='d-flex flex-row' style={{ alignItems: 'center', cursor: 'pointer' }} onClick={() => selectAccount(2, tmp2)}><img style={{ maxWidth: '30px', fontSize: '12px' }} src="images/tokens/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c.png" alt="" /> <h2 className='p-1 acc' >{acc2}</h2></div>

                            {active[1] ?
                                <div className=' rounded-3' style={{ backgroundColor: '#FB53C1' }}>
                                    <h6 className='p-2' style={{ cursor: 'default', marginTop: '4px', color: 'white' }}>
                                        Active
                                    </h6>
                                </div> :
                                <div></div>
                            }
                            {active[1] ? <div className='d-flex flex-row mt-1' style={{ columnGap: '0px' }}><i className="far fa-clone cursor-point btn copy" onClick={() => { navigator.clipboard.writeText(account1[1]); successActive('copy-alert2') }} />
                                <button className='btn-secondary rounded px-3 ' style={{ cursor: 'no-drop' }}><i className="far fa-trash-alt" /></button>
                            </div>

                                : <div className='d-flex flex-row mt-1' style={{ columnGap: '0px' }}><i className="far fa-clone cursor-point btn copy" onClick={() => { navigator.clipboard.writeText(account1[1]); successActive('copy-alert2') }} />
                                    <button className='btn-danger rounded px-3 cursor-point' onClick={() => { removeAccount(1, tmp2) }}><i className="far fa-trash-alt cursor-point" /></button>
                                </div>
                            }
                        </div>
                    </div> : <div></div>
                }
                {
                    show3 ? <div id='account-bar' className="account-bar row d-flex flex-column mx-auto p-2 rounded-3 mt-2 bg-box" >
                        <div className='d-flex flex-row justify-content-start' style={{ position: 'relative' }}><h2 className='fs-5'>Your Wallet</h2> <div id='copy-alert3'> Copy Success</div></div>
                        <div className='d-flex flex-row justify-content-between mt-3'>
                            <div className='d-flex flex-row' style={{ alignItems: 'center', cursor: 'pointer' }} onClick={() => selectAccount(3, tmp3)}><img style={{ maxWidth: '30px', fontSize: '12px' }} src="images/tokens/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c.png" alt="" /> <h2 className='p-1 acc' >{acc3}</h2></div>
                            {active[2] ?
                                <div className=' rounded-3' style={{ backgroundColor: '#FB53C1' }}>
                                    <h6 className='p-2' style={{ cursor: 'default', marginTop: '4px', color: 'white' }}>
                                        Active
                                    </h6>
                                </div> : <div></div>

                            }
                            {active[2] ? <div className='d-flex flex-row mt-1' style={{ columnGap: '0px' }}><i className="far fa-clone cursor-point btn copy" onClick={() => { navigator.clipboard.writeText(account1[2]); successActive('copy-alert3') }} />
                                <button className='btn-secondary rounded px-3 ' style={{ cursor: 'no-drop' }}><i className="far fa-trash-alt" /></button>
                            </div>

                                : <div className='d-flex flex-row mt-1' style={{ columnGap: '0px' }}><i className="far fa-clone cursor-point btn copy" onClick={() => { navigator.clipboard.writeText(account1[2]); successActive('copy-alert3') }} />
                                    <button className='btn-danger rounded px-3 cursor-point' onClick={() => { removeAccount(2, tmp3) }}><i className="far fa-trash-alt cursor-point" /></button>
                                </div>
                            }
                        </div>
                    </div> : <div></div>
                }
            </div>
            <div className='col-md-12 col-lg-6'> <Stat data={account} /> </div>
            {show1 || show2 || show3 ? <div className='row mt-2'>
                <div className='col-lg-12 col-md-12'></div>
                <div className='col-lg-6 col-md-12 '>
                    <FarmCard data={account1} addcount={select} />
                </div>
                <div className='col-lg-6 col-md-12 '>
                    <TokenWallet data={account1} addcount={select} show={{ show1, show2, show3 }} />
                </div>

            </div>
                : <div></div>
            }




        </div>
    )
}
