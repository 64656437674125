import { Token2 } from './types'

export const token2: Token2[] = [

  {
    symbol: 'CAKE',
    address: {
      56: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
      97: '0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe',
    },
    decimals: 18,
    projectLink: 'https://pancakeswap.finance/',
  },
  {
    symbol: 'TXL',
    address: {
      56: '0x1FFD0b47127fdd4097E54521C9E2c7f0D66AafC5',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://tixl.org/',
  },
  {
    symbol: 'COS',
    address: {
      56: '0x96Dd399F9c3AFda1F194182F71600F1B65946501',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.contentos.io/',
  },
  {
    symbol: 'BUNNY',
    address: {
      56: '0xC9849E6fdB743d08fAeE3E34dd2D1bc69EA11a51',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://pancakebunny.finance/',
  },
  {
    symbol: 'ALICE',
    address: {
      56: '0xAC51066d7bEC65Dc4589368da368b212745d63E8',
      97: '',
    },
    decimals: 6,
    projectLink: 'https://www.myneighboralice.com/',
  },
  {
    symbol: 'FOR',
    address: {
      56: '0x658A109C5900BC6d2357c87549B651670E5b0539',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.for.tube/home',
  },
  {
    symbol: 'BUX',
    address: {
      56: '0x211FfbE424b90e25a15531ca322adF1559779E45',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://getbux.com/bux-crypto/',
  },
  {
    symbol: 'NULS',
    address: {
      56: '0x8CD6e29d3686d24d3C2018CEe54621eA0f89313B',
      97: '',
    },
    decimals: 8,
    projectLink: 'https://www.nuls.io/',
  },
  {
    symbol: 'BELT',
    address: {
      56: '0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://beta.belt.fi/',
  },
  {
    symbol: 'RAMP',
    address: {
      56: '0x8519EA49c997f50cefFa444d240fB655e89248Aa',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://rampdefi.com/',
  },
  {
    symbol: 'BFI',
    address: {
      56: '0x81859801b01764D4f0Fa5E64729f5a6C3b91435b',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://bearn.fi/',
  },
  {
    symbol: 'DEXE',
    address: {
      56: '0x039cB485212f996A9DBb85A9a75d898F94d38dA6',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://dexe.network/',
  },
  {
    symbol: 'BEL',
    address: {
      56: '0x8443f091997f06a61670B735ED92734F5628692F',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://bella.fi/',
  },
  {
    symbol: 'TPT',
    address: {
      56: '0xECa41281c24451168a37211F0bc2b8645AF45092',
      97: '',
    },
    decimals: 4,
    projectLink: 'https://www.tokenpocket.pro/',
  },
  {
    symbol: 'WATCH',
    address: {
      56: '0x7A9f28EB62C791422Aa23CeAE1dA9C847cBeC9b0',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://yieldwatch.net/',
  },
  {
    symbol: 'xMARK',
    address: {
      56: '0x26A5dFab467d4f58fB266648CAe769503CEC9580',
      97: '',
    },
    decimals: 9,
    projectLink: 'https://benchmarkprotocol.finance/',
  },
  {
    symbol: 'bMXX',
    address: {
      56: '0x4131b87F74415190425ccD873048C708F8005823',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://multiplier.finance/',
  },
  {
    symbol: 'IOTX',
    address: {
      56: '0x9678E42ceBEb63F23197D726B29b1CB20d0064E5',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://iotex.io/',
  },
  {
    symbol: 'BOR',
    address: {
      56: '0x92D7756c60dcfD4c689290E8A9F4d263b3b32241',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.boringdao.com/',
  },
  {
    symbol: 'bOPEN',
    address: {
      56: '0xF35262a9d427F96d2437379eF090db986eaE5d42',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://opendao.io/',
  },
  {
    symbol: 'DODO',
    address: {
      56: '0x67ee3Cb086F8a16f34beE3ca72FAD36F7Db929e2',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://dodoex.io/',
  },
  {
    symbol: 'SWINGBY',
    address: {
      56: '0x71DE20e0C4616E7fcBfDD3f875d568492cBE4739',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://swingby.network/',
  },
  {
    symbol: 'BRY',
    address: {
      56: '0xf859Bf77cBe8699013d6Dbc7C2b926Aaf307F830',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://berrydata.co/',
  },
  {
    symbol: 'ZEE',
    address: {
      56: '0x44754455564474A89358B2C2265883DF993b12F0',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://zeroswap.io/',
  },
  {
    symbol: 'SWGb',
    address: {
      56: '0xe40255c5d7fa7ceec5120408c78c787cecb4cfdb',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://swirgepay.com/',
  },
  {
    symbol: 'SWG',
    address: {
      56: '0xe792f64C582698b8572AAF765bDC426AC3aEfb6B',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://swirgepay.com/',
  },
  {
    symbol: 'SFP',
    address: {
      56: '0xD41FDb03Ba84762dD66a0af1a6C8540FF1ba5dfb',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.safepal.io/',
  },
  {
    symbol: 'LINA',
    address: {
      56: '0x762539b45A1dCcE3D36d080F74d1AED37844b878',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://linear.finance/',
  },
  {
    symbol: 'LIT',
    address: {
      56: '0xb59490aB09A0f526Cc7305822aC65f2Ab12f9723',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.litentry.com/',
  },
  {
    symbol: 'HGET',
    address: {
      56: '0xC7d8D35EBA58a0935ff2D5a33Df105DD9f071731',
      97: '',
    },
    decimals: 6,
    projectLink: 'https://www.hedget.com/',
  },
  {
    symbol: 'BDO',
    address: {
      56: '0x190b589cf9Fb8DDEabBFeae36a813FFb2A702454',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://bdollar.fi/',
  },
  {
    symbol: 'EGLD',
    address: {
      56: '0xbF7c81FFF98BbE61B40Ed186e4AfD6DDd01337fe',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://elrond.com/',
  },
  {
    symbol: 'UST',
    address: {
      56: '0x23396cf899ca06c4472205fc903bdb4de249d6fc',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://mirror.finance/',
  },
  {
    symbol: 'wSOTE',
    address: {
      56: '0x541E619858737031A1244A5d0Cd47E5ef480342c',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://soteria.finance/#/',
  },
  {
    symbol: 'FRONT',
    address: {
      56: '0x928e55daB735aa8260AF3cEDadA18B5f70C72f1b',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://frontier.xyz/',
  },
  {
    symbol: 'Helmet',
    address: {
      56: '0x948d2a81086A075b3130BAc19e4c6DEe1D2E3fE8',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.helmet.insure/',
  },
  {
    symbol: 'BTCST',
    address: {
      56: '0x78650B139471520656b9E7aA7A5e9276814a38e9',
      97: '',
    },
    decimals: 17,
    projectLink: 'https://www.1-b.tc/',
  },
  {
    symbol: 'BSCX',
    address: {
      56: '0x5Ac52EE5b2a633895292Ff6d8A89bB9190451587',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://bscex.org/',
  },
  {
    symbol: 'TEN',
    address: {
      56: '0xdFF8cb622790b7F92686c722b02CaB55592f152C',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.tenet.farm/',
  },
  {
    symbol: 'bALBT',
    address: {
      56: '0x72fAa679E1008Ad8382959FF48E392042A8b06f7',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://allianceblock.io/',
  },
  {
    symbol: 'ASR',
    address: {
      56: '0x80D5f92C2c8C682070C95495313dDB680B267320',
      97: '',
    },
    decimals: 2,
    projectLink: 'https://www.chiliz.com',
  },
  {
    symbol: 'ATM',
    address: {
      56: '0x25E9d05365c867E59C1904E7463Af9F312296f9E',
      97: '',
    },
    decimals: 2,
    projectLink: 'https://www.chiliz.com',
  },
  {
    symbol: 'OG',
    address: {
      56: '0xf05E45aD22150677a017Fbd94b84fBB63dc9b44c',
      97: '',
    },
    decimals: 2,
    projectLink: 'https://www.chiliz.com',
  },
  {
    symbol: 'REEF',
    address: {
      56: '0xF21768cCBC73Ea5B6fd3C687208a7c2def2d966e',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://reef.finance/',
  },
  {
    symbol: 'DITTO',
    address: {
      56: '0x233d91A0713155003fc4DcE0AFa871b508B3B715',
      97: '',
    },
    decimals: 9,
    projectLink: 'https://ditto.money/',
  },
  {
    symbol: 'JUV',
    address: {
      56: '0xC40C9A843E1c6D01b7578284a9028854f6683b1B',
      97: '',
    },
    decimals: 2,
    projectLink: 'https://www.chiliz.com',
  },
  {
    symbol: 'PSG',
    address: {
      56: '0xBc5609612b7C44BEf426De600B5fd1379DB2EcF1',
      97: '',
    },
    decimals: 2,
    projectLink: 'https://www.chiliz.com',
  },
  {
    symbol: 'VAI',
    address: {
      56: '0x4BD17003473389A42DAF6a0a729f6Fdb328BbBd7',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://venus.io/',
  },
  {
    symbol: 'wBNB',
    address: {
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
    },
    decimals: 18,
    projectLink: 'https://pancakeswap.finance/',
  },

  {
    symbol: 'BLINK',
    address: {
      56: '0x63870A18B6e42b01Ef1Ad8A2302ef50B7132054F',
      97: '',
    },
    decimals: 6,
    projectLink: 'https://blink.wink.org',
  },
  {
    symbol: 'UNFI',
    address: {
      56: '0x728C5baC3C3e370E372Fc4671f9ef6916b814d8B',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://unifiprotocol.com',
  },
  {
    symbol: 'TWT',
    address: {
      56: '0x4B0F1812e5Df2A09796481Ff14017e6005508003',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://trustwallet.com/',
  },
  {
    symbol: 'HARD',
    address: {
      56: '0xf79037F6f6bE66832DE4E7516be52826BC3cBcc4',
      97: '',
    },
    decimals: 6,
    projectLink: 'https://hard.kava.io',
  },
  {
    symbol: 'bROOBEE',
    address: {
      56: '0xE64F5Cb844946C1F102Bd25bBD87a5aB4aE89Fbe',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://roobee.io/',
  },
  {
    symbol: 'STAX',
    address: {
      56: '0x0Da6Ed8B13214Ff28e9Ca979Dd37439e8a88F6c4',
      97: '',
    },
    decimals: 18,
    projectLink: 'http://stablexswap.com/',
  },
  {
    symbol: 'NAR',
    address: {
      56: '0xA1303E6199b319a891b79685F0537D289af1FC83',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://narwhalswap.org/',
  },
  {
    symbol: 'NYA',
    address: {
      56: '0xbFa0841F7a90c4CE6643f651756EE340991F99D5',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://nyanswop.org/',
  },
  {
    symbol: 'CTK',
    address: {
      56: '0xA8c2B8eec3d368C0253ad3dae65a5F2BBB89c929',
      97: '',
    },
    decimals: 6,
    projectLink: 'https://www.certik.foundation/',
  },
  {
    symbol: 'INJ',
    address: {
      56: '0xa2B726B1145A4773F68593CF171187d8EBe4d495',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://injectiveprotocol.com/',
  },
  {
    symbol: 'SXP',
    address: {
      56: '0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://swipe.io/',
  },
  {
    symbol: 'ALPHA',
    address: {
      56: '0xa1faa113cbE53436Df28FF0aEe54275c13B40975',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://alphafinance.io/',
  },
  {
    symbol: 'XVS',
    address: {
      56: '0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://venus.io/',
  },
  {
    symbol: 'SUSHI',
    address: {
      56: '0x947950BcC74888a40Ffa2593C5798F11Fc9124C4',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://sushi.com/',
  },
  {
    symbol: 'COMP',
    address: {
      56: '0x52CE071Bd9b1C4B00A0b92D298c512478CaD67e8',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://compound.finance/',
  },
  {
    symbol: 'SYRUP',
    address: {
      56: '0x009cF7bC57584b7998236eff51b98A168DceA9B0',
      97: '0xfE1e507CeB712BDe086f3579d2c03248b2dB77f9',
    },
    decimals: 18,
    projectLink: 'https://pancakeswap.finance/',
  },
  {
    symbol: 'BIFI',
    address: {
      56: '0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://beefy.finance/',
  },
  {
    symbol: 'DUSK',
    address: {
      56: '0xB2BD0749DBE21f623d9BABa856D3B0f0e1BFEc9C',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://dusk.network/',
  },
  {
    symbol: 'BUSD',
    address: {
      56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.paxos.com/busd/',
  },
  {
    symbol: 'ETH',
    address: {
      56: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://ethereum.org/en/',
  },
  {
    symbol: 'BETH',
    address: {
      56: '0x250632378E573c6Be1AC2f97Fcdf00515d0Aa91B',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://ethereum.org/en/eth2/beacon-chain/',
  },
  {
    symbol: 'mAMZN',
    address: {
      56: '0x3947B992DC0147D2D89dF0392213781b04B25075',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://mirror.finance/',
  },
  {
    symbol: 'mGOOGL',
    address: {
      56: '0x62D71B23bF15218C7d2D7E48DBbD9e9c650B173f',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://mirror.finance/',
  },
  {
    symbol: 'mNFLX',
    address: {
      56: '0xa04F060077D90Fe2647B61e4dA4aD1F97d6649dc',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://mirror.finance/',
  },
  {
    symbol: 'mTSLA',
    address: {
      56: '0xF215A127A196e3988C09d052e16BcFD365Cd7AA3',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://mirror.finance/',
  },
  {
    symbol: 'LTC',
    address: {
      56: '0x4338665CBB7B2485A8855A139b75D5e34AB0DB94',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://litecoin.org/',
  },
  {
    symbol: 'USDC',
    address: {
      56: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.centre.io/usdc',
  },
  {
    symbol: 'DAI',
    address: {
      56: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
      97: '',
    },
    decimals: 18,
    projectLink: 'http://www.makerdao.com/',
  },
  {
    symbol: 'ADA',
    address: {
      56: '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://www.cardano.org/',
  },
  {
    symbol: 'BAND',
    address: {
      56: '0xAD6cAEb32CD2c308980a548bD0Bc5AA4306c6c18',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://bandprotocol.com/',
  },
  {
    symbol: 'DOT',
    address: {
      56: '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://polkadot.network/',
  },
  {
    symbol: 'EOS',
    address: {
      56: '0x56b6fB708fC5732DEC1Afc8D8556423A2EDcCbD6',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://eos.io/',
  },
  {
    symbol: 'LINK',
    address: {
      56: '0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://chain.link/',
  },
  {
    symbol: 'USDT',
    address: {
      56: '0x55d398326f99059fF775485246999027B3197955',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://tether.to/',
  },
  {
    symbol: 'BTCB',
    address: {
      56: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://bitcoin.org/',
  },
  {
    symbol: 'XRP',
    address: {
      56: '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://ripple.com/xrp/',
  },
  {
    symbol: 'ATOM',
    address: {
      56: '0x0Eb3a705fc54725037CC9e008bDede697f62F335',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://cosmos.network/',
  },
  {
    symbol: 'YFII',
    address: {
      56: '0x7F70642d88cf1C4a3a7abb072B53B929b653edA5',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://dfi.money/#/',
  },
  {
    symbol: 'XTZ',
    address: {
      56: '0x16939ef78684453bfDFb47825F8a5F714f12623a',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://www.tezos.com/',
  },
  {
    symbol: 'BCH',
    address: {
      56: '0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'http://bch.info/',
  },
  {
    symbol: 'YFI',
    address: {
      56: '0x88f1A5ae2A3BF98AEAF342D26B30a79438c9142e',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://yearn.finance/',
  },
  {
    symbol: 'UNI',
    address: {
      56: '0xBf5140A22578168FD562DCcF235E5D43A02ce9B1',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://uniswap.org/',
  },
  {
    symbol: 'FIL',
    address: {
      56: '0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://filecoin.io/',
  },
  {
    symbol: 'BAKE',
    address: {
      56: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.bakeryswap.org/',
  },
  {
    symbol: 'BURGER',
    address: {
      56: '0xAe9269f27437f0fcBC232d39Ec814844a51d6b8f',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://burgerswap.org/',
  },
  {
    symbol: 'bDIGG',
    address: {
      56: '0x5986D5c77c65e5801a5cAa4fAE80089f870A71dA',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://badger.finance/',
  },
  {
    symbol: 'bBadger',
    address: {
      56: '0x1F7216fdB338247512Ec99715587bb97BBf96eae',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://badger.finance/',
  },
  {
    symbol: 'TRADE',
    address: {
      56: '0x7af173F350D916358AF3e218Bdf2178494Beb748',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://unitrade.app/',
  },
  {
    symbol: 'PNT',
    address: {
      56: '0xdaacB0Ab6Fb34d24E8a67BfA14BF4D95D4C7aF92',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://ptokens.io/',
  },
  {
    symbol: 'MIR',
    address: {
      56: '0x5B6DcF557E2aBE2323c48445E8CC948910d8c2c9',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://mirror.finance/',
  },
  {
    symbol: 'pBTC',
    address: {
      56: '0xeD28A457A5A76596ac48d87C0f577020F6Ea1c4C',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://ptokens.io/',
  },
  {
    symbol: 'LTO',
    address: {
      56: '0x857B222Fc79e1cBBf8Ca5f78CB133d1b7CF34BBd',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://ltonetwork.com/',
  },
  {
    symbol: 'pCWS',
    address: {
      56: '0xbcf39F0EDDa668C58371E519AF37CA705f2bFcbd',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://game.seascape.network/',
  },
  {
    symbol: 'ZIL',
    address: {
      56: '0xb86AbCb37C3A4B64f74f59301AFF131a1BEcC787',
      97: '',
    },
    decimals: 12,
    projectLink: 'https://www.zilliqa.com/',
  },
  {
    symbol: 'LIEN',
    address: {
      56: '0x5d684ADaf3FcFe9CFb5ceDe3abf02F0Cdd1012E3',
      97: '',
    },
    decimals: 8,
    projectLink: 'https://lien.finance/',
  },
  {
    symbol: 'SWTH',
    address: {
      56: '0x250b211EE44459dAd5Cd3bCa803dD6a7EcB5d46C',
      97: '',
    },
    decimals: 8,
    projectLink: 'https://switcheo.network/',
  },
  {
    symbol: 'DFT',
    address: {
      56: '0x42712dF5009c20fee340B245b510c0395896cF6e',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.dfuture.com/home',
  },
  {
    symbol: 'GUM',
    address: {
      56: '0xc53708664b99DF348dd27C3Ac0759d2DA9c40462',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://gourmetgalaxy.io/',
  },
  {
    symbol: 'DEGO',
    address: {
      56: '0x3FdA9383A84C05eC8f7630Fe10AdF1fAC13241CC',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://bsc.dego.finance/home',
  },
  {
    symbol: 'NRV',
    address: {
      56: '0x42F6f551ae042cBe50C739158b4f0CAC0Edb9096',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://nerve.fi/',
  },
  {
    symbol: 'EASY',
    address: {
      56: '0x7C17c8bED8d14bAccE824D020f994F4880D6Ab3B',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://easyfi.network/',
  },
  {
    symbol: 'ODDZ',
    address: {
      56: '0xCD40F2670CF58720b694968698A5514e924F742d',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://oddz.fi/',
  },
  {
    symbol: 'HOO',
    address: {
      56: '0xE1d1F66215998786110Ba0102ef558b22224C016',
      97: '',
    },
    decimals: 8,
    projectLink: 'https://hoo.com/',
  },
  {
    symbol: 'APYS',
    address: {
      56: '0x37dfACfaeDA801437Ff648A1559d73f4C40aAcb7',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://apyswap.com/',
  },
  {
    symbol: 'BONDLY',
    address: {
      56: '0x96058f8C3e16576D9BD68766f3836d9A33158f89',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.bondly.finance/',
  },
  {
    symbol: 'TKO',
    address: {
      56: '0x9f589e3eabe42ebC94A44727b3f3531C0c877809',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.tokocrypto.com/',
  },
  {
    symbol: 'ITAM',
    address: {
      56: '0x04C747b40Be4D535fC83D09939fb0f626F32800B',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://itam.network/',
  },
  {
    symbol: 'ARPA',
    address: {
      56: '0x6F769E65c14Ebd1f68817F5f1DcDb61Cfa2D6f7e',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://arpachain.io/',
  },
  {
    symbol: 'EPS',
    address: {
      56: '0xA7f552078dcC247C2684336020c03648500C6d9F',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://ellipsis.finance/',
  },
  {
    symbol: 'JGN',
    address: {
      56: '0xC13B7a43223BB9Bf4B69BD68Ab20ca1B79d81C75',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://jgndefi.com/',
  },
  {
    symbol: 'TLM',
    address: {
      56: '0x2222227E22102Fe3322098e4CBfE18cFebD57c95',
      97: '',
    },
    decimals: 4,
    projectLink: 'https://alienworlds.io/',
  },
  {
    symbol: 'PERL',
    address: {
      56: '0x0F9E4D49f25de22c2202aF916B681FBB3790497B',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://perlinx.finance/',
  },
  {
    symbol: 'ALPA',
    address: {
      56: '0xc5E6689C9c8B02be7C49912Ef19e79cF24977f03',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://bsc.alpaca.city/',
  },
  {
    symbol: 'HZN',
    address: {
      56: '0xC0eFf7749b125444953ef89682201Fb8c6A917CD',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://horizonprotocol.com/',
  },
  {
    symbol: 'SUTER',
    address: {
      56: '0x4CfbBdfBd5BF0814472fF35C72717Bd095ADa055',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://shield.suterusu.io/',
  },
  {
    symbol: 'CGG',
    address: {
      56: '0x1613957159E9B0ac6c80e824F7Eea748a32a0AE2',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://chainguardians.io/',
  },
  {
    symbol: 'MIX',
    address: {
      56: '0xB67754f5b4C704A24d2db68e661b2875a4dDD197',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://mixie.chainguardians.io/',
  },
  {
    symbol: 'HAKKA',
    address: {
      56: '0x1D1eb8E8293222e1a29d2C0E4cE6C0Acfd89AaaC',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://hakka.finance/',
  },
  {
    symbol: 'XED',
    address: {
      56: '0x5621b5A3f4a8008c4CCDd1b942B121c8B1944F1f',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.exeedme.com/',
  },
  {
    symbol: 'τBTC',
    address: {
      56: '0x2cD1075682b0FCCaADd0Ca629e138E64015Ba11c',
      97: '',
    },
    decimals: 9,
    projectLink: 'https://www.btcst.finance/',
  },
  {
    symbol: 'ALPACA',
    address: {
      56: '0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.alpacafinance.org/',
  },
  {
    symbol: 'DFD',
    address: {
      56: '0x9899a98b222fCb2f3dbee7dF45d943093a4ff9ff',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://dusd.finance/',
  },
  {
    symbol: 'LMT',
    address: {
      56: '0x9617857E191354dbEA0b714d78Bc59e57C411087',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://lympo.io/lmt/',
  },
  {
    symbol: 'BTT',
    address: {
      56: '0x8595F9dA7b868b1822194fAEd312235E43007b49',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.bittorrent.com/',
  },
  {
    symbol: 'TRX',
    address: {
      56: '0x85EAC5Ac2F758618dFa09bDbe0cf174e7d574D5B',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://tron.network/',
  },
  {
    symbol: 'WIN',
    address: {
      56: '0xaeF0d72a118ce24feE3cD1d43d383897D05B4e99',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://winklink.org/',
  },
  {
    symbol: 'mCOIN',
    address: {
      56: '0x49022089e78a8D46Ec87A3AF86a1Db6c189aFA6f',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://mirror.finance/',
  },
  {
    symbol: 'MATH',
    address: {
      56: '0xF218184Af829Cf2b0019F8E6F0b2423498a36983',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://mathwallet.org/',
  },
  {
    symbol: 'KUN',
    address: {
      56: '0x1A2fb0Af670D0234c2857FaD35b789F8Cb725584',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://chemix.io/home',
  },
  {
    symbol: 'QSD',
    address: {
      56: '0x07AaA29E63FFEB2EBf59B33eE61437E1a91A3bb2',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://chemix.io/home',
  },
  {
    symbol: 'HYFI',
    address: {
      56: '0x9a319b959e33369C5eaA494a770117eE3e585318',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://hyfi.pro/#/',
  },
  {
    symbol: 'OIN',
    address: {
      56: '0x658E64FFcF40D240A43D52CA9342140316Ae44fA',
      97: '',
    },
    decimals: 8,
    projectLink: 'https://oin.finance/',
  },
  {
    symbol: 'DOGE',
    address: {
      56: '0xbA2aE424d960c26247Dd6c32edC70B295c744C43',
      97: '',
    },
    decimals: 8,
    projectLink: 'https://dogecoin.com/',
  },
  {
    symbol: 'FINE',
    address: {
      56: '0x4e6415a5727ea08aAE4580057187923aeC331227',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://refinable.com/',
  },
  {
    symbol: 'ONE',
    address: {
      56: '0x04BAf95Fd4C52fd09a56D840bAEe0AB8D7357bf0',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.bigone.com/',
  },
  {
    symbol: 'PMON',
    address: {
      56: '0x1796ae0b0fa4862485106a0de9b654eFE301D0b2',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://polkamon.com/',
  },
  {
    symbol: 'HOTCROSS',
    address: {
      56: '0x4FA7163E153419E0E1064e418dd7A99314Ed27b6',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.hotcross.com/',
  },
  {
    symbol: 'τDOGE',
    address: {
      56: '0xe550a593d09FBC8DCD557b5C88Cea6946A8b404A',
      97: '',
    },
    decimals: 8,
    projectLink: 'https://www.btcst.finance/',
  },
  {
    symbol: 'BTR',
    address: {
      56: '0x5a16E8cE8cA316407c6E6307095dc9540a8D62B3',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.bitrue.com/',
  },
  {
    symbol: 'UBXT',
    address: {
      56: '0xBbEB90cFb6FAFa1F69AA130B7341089AbeEF5811',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://upbots.com/',
  },
  {
    symbol: 'WMASS',
    address: {
      56: '0x7e396BfC8a2f84748701167c2d622F041A1D7a17',
      97: '',
    },
    decimals: 8,
    projectLink: 'https://massnet.org/en/',
  },
  {
    symbol: 'RFOX',
    address: {
      56: '0x0a3A21356793B49154Fd3BbE91CBc2A16c0457f5',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.redfoxlabs.io/',
  },
  {
    symbol: 'XEND',
    address: {
      56: '0x4a080377f83D669D7bB83B3184a8A5E61B500608',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://xend.finance/',
  },
  {
    symbol: 'CYC',
    address: {
      56: '0x810EE35443639348aDbbC467b33310d2AB43c168',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://cyclone.xyz/',
  },
  {
    symbol: 'CHR',
    address: {
      56: '0xf9CeC8d50f6c8ad3Fb6dcCEC577e05aA32B224FE',
      97: '',
    },
    decimals: 6,
    projectLink: 'https://chromia.com/',
  },
  {
    symbol: 'KALM',
    address: {
      56: '0x4BA0057f784858a48fe351445C672FF2a3d43515',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://kalmar.io/',
  },
  {
    symbol: 'DERI',
    address: {
      56: '0xe60eaf5A997DFAe83739e035b005A33AfdCc6df5',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://deri.finance/#/index',
  },
  {
    symbol: 'WELL',
    address: {
      56: '0xf07a32Eb035b786898c00bB1C64d8c6F8E7a46D5',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.bitwellex.com/',
  },
  {
    symbol: 'pOPEN',
    address: {
      56: '0xaBaE871B7E3b67aEeC6B46AE9FE1A91660AadAC5',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://opendao.io/',
  },
  {
    symbol: 'EZ',
    address: {
      56: '0x5512014efa6Cd57764Fa743756F7a6Ce3358cC83',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://easyfi.network/',
  },
  {
    symbol: 'VRT',
    address: {
      56: '0x5F84ce30DC3cF7909101C69086c50De191895883',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://venus.io/',
  },
  {
    symbol: 'TUSD',
    address: {
      56: '0x14016E85a25aeb13065688cAFB43044C2ef86784',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.trueusd.com/',
  },
  {
    symbol: 'MTRG',
    address: {
      56: '0xBd2949F67DcdC549c6Ebe98696449Fa79D988A9F',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.meter.io/',
  },
  {
    symbol: 'KTN',
    address: {
      56: '0xDAe6c2A48BFAA66b43815c5548b10800919c993E',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://kattana.io/',
  },
  {
    symbol: 'QKC',
    address: {
      56: '0xA1434F1FC3F437fa33F7a781E041961C0205B5Da',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://quarkchain.io/',
  },
  {
    symbol: 'bCFX',
    address: {
      56: '0x045c4324039dA91c52C55DF5D785385Aab073DcF',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.confluxnetwork.org/',
  },
  {
    symbol: 'MX',
    address: {
      56: '0x9F882567A62a5560d147d64871776EeA72Df41D3',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.mxc.com/',
  },
  {
    symbol: 'ATA',
    address: {
      56: '0xA2120b9e674d3fC3875f415A7DF52e382F141225',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.ata.network/',
  },
  {
    symbol: 'MBOX',
    address: {
      56: '0x3203c9E46cA618C8C1cE5dC67e7e9D75f5da2377',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.mobox.io/#/',
  },
  {
    symbol: 'BORING',
    address: {
      56: '0xffEecbf8D7267757c2dc3d13D730E97E15BfdF7F',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.boringdao.com/',
  },
  {
    symbol: 'MARSH',
    address: {
      56: '0x2FA5dAF6Fe0708fBD63b1A7D1592577284f52256',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://unmarshal.io/',
  },
  {
    symbol: 'AMPL',
    address: {
      56: '0xDB021b1B247fe2F1fa57e0A87C748Cc1E321F07F',
      97: '',
    },
    decimals: 9,
    projectLink: 'https://www.ampleforth.org/',
  },
  {
    symbol: 'O3',
    address: {
      56: '0xEe9801669C6138E84bD50dEB500827b776777d28',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://o3swap.com/',
  },
  {
    symbol: 'HAI',
    address: {
      56: '0xaA9E582e5751d703F85912903bacADdFed26484C',
      97: '',
    },
    decimals: 8,
    projectLink: 'https://hacken.io/',
  },
  {
    symbol: 'HTB',
    address: {
      56: '0x4e840AADD28DA189B9906674B4Afcb77C128d9ea',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.hotbit.io/',
  },
  {
    symbol: 'VEMO',
    address: {
      56: '0xc8874377aB1Fd172bC4B0Ed672a188bdAC1F20ca',
      97: '',
    },
    decimals: 9,
  },
  {
    symbol: 'VEMO',
    address: {
      56: '0x39e6e19ff5f3f83b7cd2aaea3327fa29679d1452',
      97: '',
    },
    decimals: 8,
  },
  {
    symbol: 'VSNT',
    address: {
      56: '0x42577772e7bDC777C809CADEA681563e2Ba7a7f5',
      97: '',
    },

    decimals: 9,
  },
  {
    symbol: 'DOP',
    address: {
      56: '0x844FA82f1E54824655470970F7004Dd90546bB28',
      97: '',
    },

    decimals: 18,
  },
  {
    symbol: 'DOLLY',
    address: {
      56: '0xfF54da7CAF3BC3D34664891fC8f3c9B6DeA6c7A5',
      97: '',
    },

    decimals: 18,
  },
  {
    symbol: 'AVAX',
    address: {
      56: '0x1ce0c2827e2ef14d5c4f29a091d735a204794041',
      97: '',
    },

    decimals: 18,
  },
  {
    symbol: 'ZEC',
    address: {
      56: '0x1ba42e5193dfa8b03d15dd1b86a3113bbbef8eeb',
      97: '',
    },
    decimals: 18,
    
  },
   {
    symbol: 'COTI',
    address: {
      56: '0xAdBAF88B39D37Dc68775eD1541F1bf83A5A45feB',
      97: '',
    },
    decimals: 18,
   
  },
  {
    symbol: 'NEAR',
    address: {
      56: '0x1Fa4a73a3F0133f0025378af00236f3aBDEE5D63',
      97: '',
    },
    decimals: 18,
    
  },
  {
    symbol: 'MOONLIGHT',
    address: {
      56: '0xB1CeD2e320E3f4C8e3511B1DC59203303493F382',
      97: '',
    },
    decimals: 9,
  
  },
{
    symbol: 'SNX',
    address: {
      56: '0x9ac983826058b8a9c7aa1c9171441191232e8404',
      97: '',
    },
    decimals: 18,
   
  },
{
    symbol: 'BLZ',
    address: {
      56: '0x935a544Bf5816E3A7C13DB2EFe3009Ffda0aCdA2',
      97: '',
    },
    decimals: 18,
  
  },
 {
    symbol: 'TAPE',
    address: {
      56: '0xf63400ee0420ce5b1ebdee0c942d7de1c734a41f',
      97: '',
    },
    decimals: 18,
 
  },
  {
    symbol: 'CELR',
    address: {
      56: '0x1f9f6a696c6fd109cd3956f45dc709d2b3902163',
      97: '',
    },
    decimals: 18,
  
  },
 {
    symbol: 'SHIB',
    address: {
      56: '0x2859e4544c4bb03966803b044a93563bd2d0dd4d',
      97: '',
    },
    decimals: 18,
 
  },
 {
    symbol: 'CRUSH',
    address: {
      56: '0x0ef0626736c2d484a792508e99949736d0af807e',
      97: '',
    },
    decimals: 18,
   
  },
 {
    symbol: 'TYPH',
    address: {
      56: '0x4090e535F2e251F5F88518998B18b54d26B3b07c',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://typhoon.network/',
  },
 {
    symbol: 'SPACE',
    address: {
      56: '0xe486a69E432Fdc29622bF00315f6b34C99b45e80',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://aperocket.finance/',
  },
   {
    symbol: 'FEG',
    address: {
      56: '0xacFC95585D80Ab62f67A14C566C1b7a49Fe91167',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://fegtoken.com/',
  },
 {
    symbol: 'GRAND',
    address: {
      56: '0xee814f5b2bf700d2e843dc56835d28d095161dd9',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.thegrandbanks.finance/#/',
  },
 {
    symbol: 'HIFI',
    address: {
      56: '0x0A38bc18022b0cCB043F7b730B354d554C6230F1',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },
{
    symbol: 'GMR',
    address: {
      56: '0x0523215DCafbF4E4aA92117d13C6985a3BeF27D7',
      97: '',
    },
    decimals: 9,
    projectLink: 'https://www.gmr.finance/',
  },
{
    symbol: 'HERO',
    address: {
      56: '0x9b26e16377ad29a6ccc01770bcfb56de3a36d8b2',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.farmhero.io/',
  },
  {
    symbol: 'Foxy',
    address: {
      56: '0x4354a4f710182966e55ea30cfa807fa1b821a67b',
      97: '',
    },
    decimals: 9,
    projectLink: 'https://foxynft.org/',
  },
 {
    symbol: 'WYVERN',
    address: {
      56: '0x470862Af0cf8D27EBFE0Ff77B0649779C29186Db',
      97: '',
    },
    decimals: 9,
    projectLink: 'https://wyverntoken.com/',
  },
 {
    symbol: 'BOG',
    address: {
      56: '0xb09fe1613fe03e7361319d2a43edc17422f36b09',
      97: '',
    },
    decimals: 9,
    projectLink: 'https://bogtools.io/',
  },
 {
    symbol: 'Scam',
    address: {
      56: '0x00aa85e010204068b7CC2235800B2d8036bdbF2E',
      97: '',
    },
    decimals: 10,
    projectLink: 'https://scamily.io/',
  },
{
    symbol: 'TWIN',
    address: {
      56: '0x62907ad5c2d79e2a4f048a90ae2b49d062a773f3',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://twin.finance/',
  },
 {
    symbol: 'LORY',
    address: {
      56: '0xcd5d75dbe75449a9021b6c570a41959eb571c751',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://app.yieldparrot.finance/#/',
  },
  {
    symbol: 'FRUIT',
    address: {
      56: '0x4ecfb95896660aa7f54003e967e7b283441a2b0a',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.fruitsadventures.com/',
  },
 {
    symbol: 'SKILL',
    address: {
      56: '0x154a9f9cbd3449ad22fdae23044319d6ef2a1fab',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },
  {
    symbol: 'GNT',
    address: {
      56: '0xf750a26eb0acf95556e8529e72ed530f3b60f348',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.greentrusttoken.com/',
  },
 {
    symbol: 'PACOCA',
    address: {
      56: '0x55671114d774ee99d653d6c12460c780a67f1d18',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://pacoca.io/',
  },
   {
    symbol: 'NEWB',
    address: {
      56: '0x545f90dc35ca1e6129f1fed354b3e2df12034261',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.newb.farm/#/home',
  },
   {
    symbol: 'BISON',
    address: {
      56: '0x19a6da6e382b85f827088092a3dbe864d9ccba73',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },
  {
    symbol: 'STARS',
    address: {
      56: '0xbd83010eb60f12112908774998f65761cf9f6f9a',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },
  {
    symbol: 'CAPS',
    address: {
      56: '0xffba7529ac181c2ee1844548e6d7061c9a597df4',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },
   {
    symbol: 'SISTA',
    address: {
      56: '0xca6d25c10dad43ae8be0bc2af4d3cd1114583c08',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://srnartgallery.com',
  },
   {
    symbol: 'BIRB',
    address: {
      56: '0x82a479264b36104be4fdb91618a59a4fc0f50650',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://birb.com/',
  },
 {
    symbol: 'AXS',
    address: {
      56: '0x715d400f88c167884bbcc41c5fea407ed4d2f8a0',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://axieinfinity.com/',
  },
   {
    symbol: 'DINOP',
    address: {
      56: '0xea90dc6f64d18771ca1dac8098526a9082265b42',
      97: '',
    },
    decimals: 9,
    projectLink: 'https://www.dinopark.fun/',
  },
  {
    symbol: 'MARU',
    address: {
      56: '0x215f51990a0ca5aa360a177181f014e2da376e5e',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },
   {
    symbol: 'PERA',
    address: {
      56: '0xb9d8592e16a9c1a3ae6021cddb324eac1cbc70d6',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://pera.finance/',
  },
   {
    symbol: 'LAND',
    address: {
      56: '0x9d986a3f147212327dd658f712d5264a73a1fdb0',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://landshare.io/',
  },
 {
    symbol: 'POTS',
    address: {
      56: '0x3fcca8648651e5b974dd6d3e50f61567779772a8',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },
   {
    symbol: 'SACT',
    address: {
      56: '0x1bA8c21c623C843Cd4c60438d70E7Ad50f363fbb',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://srnartgallery.com/',
  },
   {
    symbol: 'GUARD',
    address: {
      56: '0xf606bd19b1e61574ed625d9ea96c841d4e247a32',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },
   {
    symbol: 'BHC',
    address: {
      56: '0x6fd7c98458a943f469e1cf4ea85b173f5cd342f4',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://billionhappiness.finance/',
  },
 {
    symbol: 'CYT',
    address: {
      56: '0xd9025e25Bb6cF39f8c926A704039D2DD51088063',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },
  {
    symbol: 'FOOT',
    address: {
      56: '0x1c64fd4f55e1a3c1f737dfa47ee5f97eaf413cf0',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },
{
    symbol: 'NABOX',
    address: {
      56: '0x755f34709e369d37c6fa52808ae84a32007d1155',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://nabox.io/',
  },
  {
    symbol: 'BABI',
    address: {
      56: '0xec15a508a187e8ddfe572a5423faa82bbdd65120',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },
  {
    symbol: 'BANANA',
    address: {
      56: '0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://apeswap.finance/',
  },
  {
    symbol: 'BREW',
    address: {
      56: '0x790be81c3ca0e53974be2688cdb954732c9862e1',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://cafeswap.finance/',
  },
  {
    symbol: 'GFCE',
    address: {
      56: '0x5f136383e230f972739fae2e81e7e774afe64c66',
      97: '',
    },
    decimals: 9,
    projectLink: 'https://jetfuel.finance/',
  },
  {
    symbol: 'NAUT',
    address: {
      56: '0x5f136383e230f972739fae2e81e7e774afe64c66',
      97: '',
    },
    decimals: 9,
    projectLink: 'https://jetfuel.finance/',
  },
  {
    symbol: 'BAT',
    address: {
      56: '0x101d82428437127bf1608f699cd651e6abf9766e',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://basicattentiontoken.org/',
  },
  {
    symbol: 'IOTA',
    address: {
      56: '0xd944f1d1e9d5f9bb90b62f9d45e447d989580782',
      97: '',
    },
    decimals: 6,
    projectLink: 'https://www.iota.org/',
  },
  {
    symbol: 'Rocket',
    address: {
      56: '0x3ba5aee47bb7eae40eb3d06124a74eb89da8ffd2',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://rocketgame.vip/',
  },
  {
    symbol: 'JDI',
    address: {
      56: '0x0491648c910ad2c1afaab733faf71d30313df7fc',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.jdiyield.com/',
  },
  {
    symbol: 'NUTS',
    address: {
      56: '0x8893d5fa71389673c5c4b9b3cb4ee1ba71207556',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://squirrel.finance/',
  },
  {
    symbol: 'SWAMP',
    address: {
      56: '0xc5a49b4cbe004b6fd55b30ba1de6ac360ff9765d',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://swamp.finance/',
  },
  {
    symbol: 'KEYFI',
    address: {
      56: '0x4b6000f9163de2e3f0a01ec37e06e1469dbbce9d',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },
  {
    symbol: 'ONT',
    address: {
      56: '0xfd7b3a77848f1c2d67e05e54d78d174a0c850335',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://ont.io/',
  },
  {
    symbol: 'BSC-USD',
    address: {
      56: '0x55d398326f99059ff775485246999027b3197955',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.binance.com/en/trade/BNB_USDT',
  },
  {
    symbol: 'BFT',
    address: {
      56: '0xa4f93159ce0a4b533b443c74b89967c60a5969f8',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.bitfresh.win/',
  },
  {
    symbol: 'AUTO',
    address: {
      56: '0xa184088a740c695e156f91f5cc086a06bb78b827',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://autofarm.network/',
  },
  {
    symbol: 'bxBTC',
    address: {
      56: '0xab111d5948470ba73d98d66bbdf2798fbe093546',
      97: '',
    },
    decimals: 9,
    projectLink: '',
  },
  {
    symbol: 'vBSWAP',
    address: {
      56: '0x4f0ed527e8a95ecaa132af214dfd41f30b361600',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://bsc.valuedefi.io/',
  },
  {
    symbol: 'MATIC',
    address: {
      56: '0xcc42724c6683b7e57334c4e856f4c9965ed682bd',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://polygon.technology/',
  },
  {
    symbol: 'AAVE',
    address: {
      56: '0xfb6115445bff7b52feb98650c87f44907e58f802',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://aave.com/',
  },
  {
    symbol: 'ETC',
    address: {
      56: '0x3d6545b08693dae087e957cb1180ee38b9e3c25e',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://ethereumclassic.org/',
  },
  {
    symbol: 'FTM',
    address: {
      56: '0xad29abb318791d579433d831ed122afeaf29dcfe',
      97: '',
    },
    decimals: 18,
    projectLink: 'http://fantom.foundation/',
  },
]
// 0xB8c77482e45F1F44dE1745F52C74426C631bDD52
// 0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c