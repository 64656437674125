import React, { useEffect,useState,useMemo } from 'react'
import Axios from "axios";
import CountUp from 'react-countup';
import {usePollFarmsData, usePriceBnbBusd} from 'state/hook'
import useRefresh from 'hooks/useRefresh';
export default function Stat(props) {
    usePollFarmsData()
    const {slowRefresh} =useRefresh()
    const bnbPrice =usePriceBnbBusd()

    // add function sum all balance from wallet

    const [wallet, set_wallet] = useState(0);
    function Get_wallet() {
        Axios.get('https://api.dev.vemo.world/board_api.php').then((res)=>{set_wallet(res.data.wallet_total)});
        //Axios.get('http://localhost/api/board_api.php').then((res)=>{set_wallet(res.data.wallet_total)});
    }
    useEffect(() => {
        Get_wallet()
    }, [wallet,props.data])

    const [totalSupply, total_track] = useState(0);
    function Total_track_value() {
      //  Axios.get('http://localhost:3001/').then((res)=>{
             Axios.get('https://api.dev.vemo.world/totalwallet_value.php').then((res)=>{
    
        total_track(Number(res.data.wallet_total/10**18)*bnbPrice.toNumber())});
    }
    useMemo(() => {
        Total_track_value()
    }, [slowRefresh,bnbPrice])

    const [farmvalue, set_farm] = useState(0);
    function Get_farm_value() {
        // Axios.get('https://api.dev.vassanatoken.com/.php').then((res)=>{set_wallet(res.data.wallet_total)});
        Axios.get('https://api.dev.vemo.world/totalfarm_value.php').then((res)=>{set_farm(res.data.farm_total)});
    }
    useEffect(() => {
        Get_farm_value()
    }, [farmvalue,props.data,slowRefresh])
    return (
        <div className=''>
            <div className='rounded-3  bg-box pt-3' style={{position:'relative'}}>
                <div className='d-flex flex-row justify-content-center'><img style={{maxWidth:'50px'}} src="images/vemo-image/Icon-3-1.png" alt="" /></div>
                <div className='d-flex flex-column text-center'>   
                <CountUp
                        start={0}
                        end={totalSupply}
                        duration={3}
                        separator=","
                        decimals={2}
                        decimal="."
                        prefix="$ "
                        suffix=""
                        onEnd={() => console.log('Ended!')}
                        onStart={() => console.log('Started!')}
                    >
                    {({ countUpRef, start }) => (
                        <div>
                            <h1 className='fs-3'><span ref={countUpRef}></span></h1>
                        </div>
                    )}
                    </CountUp>
                    </div>
             
                <div className='d-flex flex-column text-center mt-1' >
                <h2 style={{color:'rgba(255, 253, 253, 0.651) '}}>Total tracked value on VEMOassetsboard</h2>
                <p className='' style={{fontSize:'0.7rem',color:'rgba(255, 253, 253, 0.651)'}}>We help you to track your investment on bsc network check your investment now !</p>
                </div>
                <div className='mt-2' style={{display:'flex',justifyContent:'center',position:'relative'}}>
                    <img className='' src="images/vemo-image/Mascot.png" alt="" style={{maxWidth:'190px'}} />
                    <img src="images/vemo-image/Mascot-02.png" alt="" style={{position:'absolute', borderBottomLeftRadius: '10px',width:'50%' ,borderBottomRightRadius: '10px' ,right:'0',bottom:'0'}}/>
                </div> 
            </div>

            <div className='mt-3 ' >
                <div className='d-flex flex-row ' style={{columnGap:'5px'}}> 
               <div className=' rounded-3 bg-box p-3 ' style={{width:'50%'}}>
               <div className='d-flex flex-row justify-content-center'><img style={{maxWidth:'50px'}} src="images/vemo-image/Icon-3-2.png" alt="" /></div>
               <div className='d-flex flex-column text-center'>
                   
               <CountUp
                        start={0}
                        end={farmvalue}
                        duration={3}
                        separator=","
                        decimals={2}
                        decimal="."
                        prefix="$ "
                        suffix=""
                        onStart={() => console.log('Started!')}
                        onEnd={() => console.log('Ended!')}
                    >
                    {({ countUpRef, start }) => (
                        <div>
                            <h1 className='fs-3'><span ref={countUpRef}></span></h1>
                        </div>
                    )}
                    </CountUp>
                   
                   </div>
                <div className='d-flex flex-column text-center mt-1'>
                <h2 style={{color:'rgba(255, 253, 253, 0.651) '}}>Tracked value in farm</h2>
                </div>
               </div>

               <div className='rounded-3 bg-box p-3' style={{width:'50%'}}>
               <div className='d-flex flex-row justify-content-center'><img style={{maxWidth:'50px'}} src="images/vemo-image/Icon-3-3.png" alt=""/></div>
               <div className='d-flex flex-column text-center'>
               <CountUp
                        start={0}
                        end={wallet}
                        duration={3}
                        separator=","   
                        prefix=""
                        suffix=""
                        onEnd={() => console.log('Ended!')}
                        onStart={() => console.log('Started!')}
                    >
                    {({ countUpRef, start }) => (
                        <div>
                            <h1 className='fs-3'><span ref={countUpRef}></span></h1>
                        </div>
                    )}
                    </CountUp>

               </div>
                <div className='d-flex flex-column text-center mt-1'>
                <h2 style={{color:'rgba(255, 253, 253, 0.651) '}}>Wallets on VEMOassetsboard</h2>
                </div>
               </div>
               </div>
            </div>
        </div>
    )
}
