import { Token2 } from './types'

export const token : Token2[] =[

{
    symbol: 'VEMO',
    address: {
      56: '0xc8874377aB1Fd172bC4B0Ed672a188bdAC1F20ca',
      97: '',
    },
    
    decimals: 9,
  },]