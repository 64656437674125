import { MAINNET_CHAIN_ID } from '../config'
import addresses from '../config/constants/contracts'
import { Address } from '../config/constants/types'


export const getAddress = (address: Address,fixChainId?): string => {
  const chainId = process.env.REACT_APP_CHAIN_ID
  if(fixChainId===1){
    return address[fixChainId]
  }
  // console.log("address", address[chainId] ? address[chainId] : address[MAINNET_CHAIN_ID])
    return address[chainId] ? address[chainId] : address[MAINNET_CHAIN_ID]
  }
export const getMulticallAddress = () => {
    return getAddress(addresses.multiCall)
  }
  export const getMulticallMainnetAddress = () => {
    return getAddress(addresses.multiCall_Mainnet)
  }

export const getjamBalance1 = () => {
    return getAddress(addresses.jamBalance1)
  }

export const getjamBalance2 = () => {
    return getAddress(addresses.jamBalance2)
  }
  export const getMasterChefAddress = () => {
    return getAddress(addresses.masterChef)
  }

  export const getMasterChefDoppleAddress = () => {
    return getAddress(addresses.masterchef_dopple)
  }

  export const getMasterChefApeAddress = () => {
    return getAddress(addresses.masterchef_ape)
  }
  export const getMasterChefSushiAddress = () => {
    return getAddress(addresses.masterchef_sushiswap)
  }

  export const getPancakeProfileAddress = () => {
    return getAddress(addresses.pancakeProfile)
  }